import React from 'react';
import { Link } from 'react-router-dom';
import img1 from "../images/custom/undraw_data_trends_re_2cdy.svg"
import img2 from "../images/custom/payroll.svg"
import img3 from "../images/custom/undraw_online_banking_re_kwqh.svg"
import img4 from "../images/custom/undraw_transfer_money_re_6o1h.svg"

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4 " id='products'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"  src={img1} />
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">FinTrack</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                FinTrack is a comprehensive financial analytics platform that empowers businesses with deep insights into their financial performance. With robust features and advanced analytics capabilities, it offers a holistic view of key metrics, trends, and patterns. Customizable dashboards and real-time reporting enable effective analysis, strategic planning, and informed decision-making for optimal financial outcomes.
                                </p>
                                {/* <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"  src={img2} />
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">WageFlex</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    
                                WageFlex empowers employees with the flexibility to access their earned wages ahead of their regular payday, promoting financial freedom. With intuitive user interfaces and robust security features, WageFlex ensures a seamless and secure experience for both employees and employers. Backed by cutting-edge technology, WageFlex simplifies the way companies assure employee financial well-being.
                                </p>
                                {/* <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div> */}
                                
                                {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"  src={img3} />
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">BankEase</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                BankEase automates the core banking processes with seamless integration with other solutions through structured APIs. Digital financial services including digital banking, BNPL processing, loans and deposits processing and a host of other banking and financial services. BankEase is the CBS engine that is the foundation of all other fintech tools necessary for a full-service digital bank or a specialized fintech service operator.

                                </p>
                                {/* <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"  src={img4} />
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">LoanSwift</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                LoanSwift is a streamlined loan origination and management platform designed to simplify and automate the lending process for businesses. With its intuitive interface and advanced features, LoanSwift enables efficient loan origination, underwriting, and management. From application submission to credit evaluation and documentation, LoanSwift streamlines the entire loan lifecycle, enhancing operational efficiency and enabling businesses to deliver seamless lending experiences to their customers.
                                </p>
                                {/* <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div> */}
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;